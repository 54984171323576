import { TokenDto } from "../shared/models/Token/TokenDto";


export default class StorageHelper {
  private static readonly tokenKey: string = 'token';
  private static readonly refreshTokenKey: string = 'refreshToken';

  static getToken(): string {
    return window.localStorage[this.tokenKey];
  }

  static getRefreshToken(): string {
    return window.localStorage[this.refreshTokenKey];
  }

  static saveToken(token: TokenDto): void {
    window.localStorage[this.tokenKey] = JSON.stringify(token);
  }

  static saveRefreshToken(token: string): void {
    window.localStorage[this.refreshTokenKey] = token;
  }

  static clearUserTokens(): void {
    window.localStorage.removeItem(this.tokenKey);
    window.localStorage.removeItem(this.refreshTokenKey);
  }
}
