import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { TokenDto } from '../shared/models/Token/TokenDto';
import StorageHelper from '../helpers/storage.helper';
import { Router } from '@angular/router';
import { Urls } from '../constants/Urls';
import { ProblemService } from './problem.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlayerGameDto } from '../shared/models/player-game/PlayerGameDto';
import { MODALS } from '../constants/Modals';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly tokenUrl: string = '/v1/token';
  private tokenSubject: BehaviorSubject<TokenDto | null>;
  public token: Observable<TokenDto | null>;
  private authenticatedSource = new BehaviorSubject<boolean>(false);
  authenticated$ = this.authenticatedSource.asObservable();

  public get tokenvalue() {
    return this.tokenSubject?.value;
  }
  
  constructor(
    private apiService: ApiService,
    private router: Router,
    private problemService: ProblemService,
    private modalService: NgbModal,
    private userService: UserService
  ) { 
    const token = StorageHelper.getToken();
    this.tokenSubject = new BehaviorSubject(token ? JSON.parse(token) : '');
    this.token = this.tokenSubject.asObservable();
  }

  login(username: string, password: string) {
    return this.apiService.post(`${this.tokenUrl}/`, { username, password })
      .pipe(
        map(token => {
          const userToken = token as TokenDto;
          this.tokenSubject.next(userToken);
          StorageHelper.saveToken(userToken);
          this.authenticatedSource.next(true);

          // trigger the get user details api request to update the user details
          this.userService.getUserDetails().subscribe();
          
          const userData = JSON.parse(atob(userToken.access.split('.')[1]));
          if (userData.first_time_login) {
            this.showFirstTimeLoginDialog();
          }
        })
      );
  }

  refreshToken() {
    const token = StorageHelper.getToken();
    if (token) {
      const userToken = JSON.parse(token) as TokenDto;
      return this.apiService.post(`${this.tokenUrl}/refresh/`, { refresh: userToken.refresh })
      .pipe(
        map(token => {
          const updatedToken = token as TokenDto;
          this.tokenSubject.next(updatedToken);
          StorageHelper.saveToken(updatedToken);
          this.authenticatedSource.next(true);
          // this.router.navigate([`${Urls.APP}/${Urls.LANDING_PAGE}`]);
          return token;
        })
      );;
    }
    return;
  }

  logout(): void {
    this.tokenSubject.next(null);
    this.authenticatedSource.next(false);
    StorageHelper.clearUserTokens();
  }

  private showFirstTimeLoginDialog(): void {
    const modalRef = this.modalService.open(MODALS['information'], { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.title = 'Game on!';
    modalRef.componentInstance.description1 = "Congratulations on taking the first step into our exciting gaming world! We're thrilled to have you here. Now that you've logged in for the first time, are you ready to play some exciting games and improve your elo score? Click play now and let the adventure begin!";
    modalRef.componentInstance.proceedButtonLabel = 'Play now';
    modalRef.componentInstance.cancelButtonLabel = 'Not now';

    modalRef.result.then((response) => {
      if (response.proceed) {
        this.problemService.startNewGame(
          {
            cfg_domains: this.problemService.domains.map(d => d.id),
            cfg_elo_level: 100,
            cfg_number_of_problems: 10,
            cfg_time_limit_seconds: 0,
            total_points: 1000,
            players:[]
          }
        ).subscribe((response: PlayerGameDto) => {
          this.router.navigate([`${Urls.APP}/${Urls.PLAYGROUND}`], { queryParams: { gameId: response.id } });
        });
      } else {
        this.router.navigate([`${Urls.APP}/${Urls.GAME_HISTORY}`])
      }
    });
  }
}
